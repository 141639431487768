// Helper class
.bg-ask {
    background-color: #fbfbfb;
}

.pull-right {
    float: right;
}

.table-responsive {
    border-radius: 5px 5px 0 0;
}

.modal-content .model-body {
    padding: 0 15px;
}

.card .list-thumb {
    background-color: #FCA311;
}

.row-small-gutter {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.row-small-gutter>* {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

body {
    p {
        line-height: 1.4;
    }
}

.en--p-15 {
    padding: 15px;
}

.en--w-120 {
    width: 120px;
}

.en--w-150 {
    width: 150px;
}

.en--w-260 {
    width: 260px;
}

.cus-color-primary {
    color: $cus-color-primary;
}

.cus-color-secondry {
    color: $secondaryColor;
}

.cus-white-color {
    color: $whiteColor;
}

.cus-black-color {
    color: $blackColor;
}

.en-plr0-tb-15 {
    padding: 0 0 15px;
}

.en-block-100 {
    display: block;
    width: 100%;
    clear: both;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-pre-98 {
    background-color: rgb(0 146 176 / 94%);
}

.pt--100 {
    margin-top: 100px;
}

.btn-know-more {
    background-color: $secondaryColor;
    color: $whiteColor;
    transition-delay: 0.3ms;

    &:hover {
        background-color: $whiteColor;
        color: $cus-color-primary;
    }
}

hr.hr-dashed {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0;
    border-top: 1px dashed #ccc;
    height: 0;
}

.round-btn {
    display: inline-block;
    // width: 40px;
    // height: 40px;
    // margin-top: 0;
    // margin-bottom: 0;
    // border-radius: 100%;
    // background: #ffffff;
    text-align: center;
    padding-top: 8px;
    // box-shadow: 0 2px 4px #0000001f;
    cursor: pointer;
    font-weight: 700;
    transition: all .2s ease-in-out;
    position: absolute;
    top: 1px;
    right: 28px;
    text-decoration-line: underline;
    color: blue;

    svg {
        fill: #000000 !important;
    }
}

//text line
.en-text-line1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.en-text-line2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}