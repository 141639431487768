.endev-active {
    background-color: darkcyan;
}

.endev {
    background-color: gainsboro;
}

.__PrivateStripeElement {
    width: 300px;
}

.ng-dropdown-panel {
    background-color: lightsteelblue
}

// .ng-select.ng-select-single .ng-select-container {
//     height: 36px;
// }

.ng-select .ng-select-container {
    color: #333;
    cursor: default;
    display: flex;
    outline: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.ng-select .ng-select-container {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    align-items: center;
}

.ng-select div,
.ng-select input,
.ng-select span {
    box-sizing: border-box;
}

.ng-select .ng-has-value .ng-placeholder {
    display: none;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #999;
}

.ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-select .ng-select-container .ng-value-container {
    display: flex;
    flex: 1;
}

.ng-select.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
    opacity: 1;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 5px;
    left: 0;
    padding-left: 10px;
    padding-right: 50px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    flex-wrap: nowrap !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #c2e0ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    display: inline-block;
    padding: 0px 5px 0px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: $cus-color-primary;
    filter: brightness(90%);
    border-radius: 2px;
    border: 1px solid #c2e0ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block;
    padding: 0px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-left: 7px;
    padding-top: 5px;
}

//drop file
// upload overwrite

.dz-success-mark {
    svg {
        g {
            path {
                fill: #74dd65;
            }
        }
    }
}

.dz-error-mark {
    svg {
        g {
            path {
                fill: #ee3861;
            }
        }
    }
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-top: 2rem;
}

.dropzone .dz-preview .dz-details .dz-filename {
    height: 15px !important;

    span {
        background-color: transparent;
    }
}

.dropzone .dz-preview .dz-remove {
    position: relative;
    z-index: 99;
}

.dropzone .dz-preview.dz-processing .dz-progress {
    display: block;
    margin-left: 0.5rem !important;
}

.error {
    color: red;
}


.lock-out {
    position: absolute;
    top: 15px;
    right: 20px;
}

label {
    span {
        color: red;
    }
}

// sweetalert2
.w-25 {
    width: 50% !important;
    margin-right: 9px;
}

.swal2-actions {
    flex-wrap: nowrap !important;
}

.form-hr {
    margin: 15px 0 15px;
    border-top: 1px dashed $gray-60;
}