// Form variables
._btn {
    height: calc(3.2rem + 2px);
    font-family: $font-family-base;
    font-weight: 500;
    border-radius: $borderRadius8x;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    &:focus,
    &:active {
        box-shadow: none;
    }
}

._form-control {
    display: block;
    height: calc(3.2rem + 2px);
    border-radius: $borderRadius8x;
    border: 1px solid $fieldBorder;
    color: $headerColor;
    font-size: 0.875rem;
}

// Form fiels
.form-group {
    position: relative;

    i {
        position: absolute;
        font-size: 24px;
        color: $primaryDarkColor;
        left: 12px;
        top: 15px;
    }

    label {
        position: absolute;
        background-color: $whiteColor;
        padding: 0 2px;
        z-index: 2;
        font-size: 0.75rem;
        top: -10px;
        left: 14px;
        color: $bodyColor;
    }

    .uiv2-show-password {
        i {
            right: 15px;
            left: auto;
        }
    }

    .form-control {
        @extend ._form-control;
        padding: 8px 15px 8px 15px;

        &.ngb-tp-input {
            height: calc(3rem + 2px);
            padding: 8px;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.uiv2-session {
        .form-control {
            padding: 8px 15px 8px 45px;
        }
    }

    .error {
        color: $dangerColor;
        margin-top: 4px;
        font-size: 13px;
        text-align: left;
    }

    &.setup-form {
        .form-control {
            &.ngb-tp-input {
                height: calc(2rem + 2px);
                padding: 8px;
            }
        }
    }
}

.btn {
    &.btn-primary {
        width: 100%;
        color: $whiteColor;
        background-color: $secondaryColor;
        @extend ._btn;

        &:hover {
            background-color: $primaryDarkColor;
        }
    }

    &.btn-tab-primary {
        width: auto;
        height: auto;
        color: $whiteColor;
        background-color: $secondaryColor;
        @extend ._btn;

        &:hover {
            background-color: $primaryDarkColor;
        }
    }

    &.btn-border {
        @extend ._btn;
        background-color: $whiteColor;
        border: 1px solid $fieldBorder;
    }

    &.btn-secondary {
        @extend ._btn;
        height: calc(2.6rem + 2px);
        background-color: $primaryLightColor;
        color: $headerColor;
        font-size: 1rem;

        &:hover {
            background-color: $secondaryColor;
            color: $whiteColor;
        }
    }

    &.btn-promo {
        @extend ._btn;
        height: 34px;
        padding: 0 10px;
        background-color: $whiteColor;
        color: $headerColor;

        &:hover {
            background-color: $secondaryColor;
            color: $whiteColor;
        }
    }
}

//Buttons MUI Animation
button {
    &.btn {
        position: relative;
        overflow: hidden;
        transition: all .15s ease-in;

        &:hover,
        &:focus {
            opacity: .9;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: currentColor;
            visibility: hidden;
            z-index: 2;
        }

        &:not(:active):before {
            animation: ripple .4s cubic-bezier(0, 0, .2, 1);
            transition: visibility .4s step-end;
        }

        &:active:before {
            visibility: visible;
        }
    }
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: .5;
    }

    100% {
        width: 200px;
        height: 200px;
        opacity: 0;
    }
}

.uiv2-country-code {
    .iti {
        &.separate-dial-code {
            width: 100%;

            input {
                background-color: $whiteColor;
                border: 1px solid $fieldBorder;
                border-radius: $borderRadius8x;
                width: 100%;
                height: calc(3.2rem + 2px);
                padding: 0.375rem 0.9rem;
            }
        }
    }

    .dropdown-menu.show {
        display: block;
        width: -webkit-fill-available;
        min-width: 390px;
    }
}

.ng-select {
    &.ng-select-single {
        .ng-select-container {
            padding: 8px 15px;
            height: calc(3.2rem + 2px);
            background: transparent;
            border: 1px solid $fieldBorder;
            border-radius: $borderRadius8x;
            z-index: 1 !important;
        }
    }
}

.form-group {
    &.uiv2-session {
        .ng-select {
            &.ng-select-single {
                .ng-select-container {
                    padding: 8px 15px 8px 45px;
                }
            }
        }
    }
}

// New wizard

.en-aw-wizard-container {
    @media only screen and (max-width: 667px) {
        margin-bottom: 1.5rem; 
    }
    .horizontal {
        &.small {
            ul {
                &.steps-indicator {
                    padding: 1rem 0;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    //background-color: $secondaryLightColor;
                    //border-radius: $borderRadius8x;
                    li {
                        width: auto;
                        a {
                            display: flex;
                            flex-direction: row-reverse;
                            align-items: center;
                            justify-content: center;
                            // position: absolute;
                            // transform: translate(-10%,-75%);
                            // left: 50%;
                            // top: 0;
                            // z-index: 1;
                            @media only screen and (max-width: 667px) {
                                left: 10%; 
                            }
                            .label {
                                position: relative;
                                text-transform: capitalize;
                                color: $bodyColor;   
                                padding: 5px 30px 5px 10px;   
                                font-weight: 400;    
                                line-height: 2;
                                text-align: left !important;
                                display: -webkit-box !important;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                -webkit-line-clamp: 1;   
                                @media only screen and (max-width: 667px) {
                                    width: 75px;
                                    padding: 5px; 
                                }  
                                &::before {
                                    content: '';
                                    position: absolute;
                                    border: solid $bodyColor; 
                                    border-width: 0 2px 2px 0;
                                    display: inline-block;
                                    padding: 3px;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    right: 10px;
                                    top: 15px;
                                    @media only screen and (max-width: 667px) {
                                        display: none;
                                    } 
                                }                                                          
                            }    
                            .step-indicator {
                                position: static;
                                width: 30px !important;
                                height: 30px !important;
                                border-radius: 50%;
                                background-color: $bodyColor;
                                @media only screen and (max-width: 667px) {
                                    width: 20px !important;
                                    height: 20px !important;
                                }
                            }
                            &:hover, &:focus {
                                .step-indicator {
                                    background-color: $headerColor;
                                }
                            }                            
                        }
                        &.current {
                            .label {
                                color: $headerColor;
                                &::before {
                                    border-color: $headerColor; 
                                } 
                            }    
                            .step-indicator {
                                background-color: $headerColor;
                            }
                        }

                        &.done {
                            &::after {
                                background-color: $headerColor;
                            }                            
                            .label {
                                color: $headerColor;
                                &::before {
                                    border-color: $headerColor; 
                                } 
                            }    
                            .step-indicator {
                                background-color: $headerColor;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    display: inline-block;
                                    transform: rotate(45deg);
                                    height: 18px;
                                    width: 10px;
                                    border-bottom: 3px solid $primaryColor;
                                    border-right: 3px solid $primaryColor;
                                    left: 9px;
                                    top: 8px;
                                    
                                    @media only screen and (max-width: 667px) {
                                        height: 12px;
                                        width: 6px;
                                        left: 7px;
                                        top: 12px;
                                    }
                                }
                            }

                            a {
                                &:hover {  
                                    .step-indicator {
                                        position: static;
                                        background-color: $headerColor !important;
                                    }
                                }
                            }
                        }
                        &::after {
                            display: none;
                        }
                        &:last-child {
                           .label {
                            &::before {
                                display: none;
                            }
                           }
                        } 
                    }
                }
            }
        }
    }
}

.en-aw-wizard-container-product {
    .horizontal {
        &.small {
            ul {
                &.steps-indicator {
                    margin-top: 0;
                    margin-bottom: 0.875rem;
                    justify-content: flex-start;
                    @media only screen and (max-width: 667px) {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }  
                    li {
                        a {
                            .label { 
                                @media only screen and (max-width: 667px) {
                                    width: auto;
                                }                             
                            } 
                        }
                    }
                }
            }
        }
    }
}