$font-family-heading: 'Inter', sans-serif;
$font-family-base: 'Inter', sans-serif;
$font-size-base: 1rem;

// Version 2023
$primaryColor: #FCA311;
$secondaryColor: #14223D;
$secondaryLightColor: #F5F5F5;
$primaryLightColor: #E5E5E5;
$primaryDarkColor: #0A1529;
$wrapperColor: #FAFAFA;

$primaryColorAlt: #FFE7C0;
$secondaryColorAlt: #e9e9e9;

$headerColor: #161616;
$bodyColor: #868890;
$blackColor: #000000;
$whiteColor: #ffffff;

$fieldBorder: #E6E7E9;

$dangerColor: #FF4949;
$successColor: #29BF70;
$infoColor: #7ADDE0;
$viewColor: #fecd67;

// Border radius

$borderRadius8x: 8px;
$borderRadius12x: 12px;
$borderRadius20x: 20px;
$borderRadius25x: 25px;

//Box shadow

$primaryBoxShadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

//Gradient

$primaryGradient : linear-gradient(180deg, rgb(250 250 250) 0%, white 100%);

// // LAYOUT VARS
// $topbar-height: 80px;
// $topbar-height-mobile: 70px;
// $sidebar-left-width: 120px;
// $sidebar-left-width-mobile: 90px;
// $sidebar-left-secondary-width: 220px;
// $sidebar-left-secondary-width-mobile: 190px;
// $side-clearance: 20px;
// Custom color
// $cus-color-primary: #14223D;
$gray-60 :#cccccc;

$cus-color-primary: var(--primary-color);
// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$success-color: #74dd65;

// Media query 

@mixin breakpoint($bp: 0) {
    @media (max-width: $bp) {
        @content;
    }
}