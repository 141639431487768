// Login page

.uiv2-login-container {
    background-color: $whiteColor;
    background: $primaryGradient;

    .uiv2-login-wrapper {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 767px) {
            padding: 15px;
        }

        .uiv2-login-wrapper-inner {
            width: 470px;
            background-color: $whiteColor;
            padding: 50px 40px;
            border-radius: $borderRadius20x;
            box-shadow: $primaryBoxShadow;

            @media only screen and (max-width: 767px) {
                width: 100%;
                padding: 50px 20px;
            }

            .uiv2-logo {
                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    .uiv2-privacy-terms-wrapper {
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.875rem;

            a {
                color: $bodyColor;
                transition: 0.3s ease-in-out;

                i {
                    font-size: 0.875rem;
                    color: $bodyColor;
                    transition: 0.3s ease-in-out;
                }

                &:hover {
                    color: $primaryColor;

                    i {
                        color: $primaryColor;
                    }
                }
            }

            span {
                font-size: 0.875rem;
            }
        }
    }
}

.uiv2-business-wrapper {
    overflow-y: scroll;
    max-height: 250px;

    &::-webkit-scrollbar {
        width: 0;
    }


    .uiv2-business-wrapper-inner {
        position: relative;
        background-color: $secondaryLightColor;
        padding: 15px 45px 15px 15px;
        border-radius: $borderRadius8x;
        margin-bottom: 10px;

        .uiv2-business-info {
            p {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: $headerColor;

                i {
                    font-size: 18px;
                    margin-right: 5px;
                }
            }
        }

        .form-check-input {
            position: absolute;
            right: 15px;
            top: 34px;
            margin: 0;
        }
    }
}

.uiv2-pro-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .uiv2-pro-container-inner {
        .uiv2-features {
            max-width: 330px;
            margin: 30px 0;

            .uiv2-cor-text {
                font-size: 0.875rem;
            }

            .carousel-inner {
                .carousel-item {
                    .carousel-caption {
                        position: initial;
                        left: 0;
                        right: 0;
                    }
                }
            }

            .carousel-indicators {
                bottom: -15px;

                li {
                    background-color: $fieldBorder;
                    border-radius: 10px;
                    height: 5px;
                    width: 10px;

                    &.active {
                        background-color: $primaryColor;
                    }
                }
            }
        }
    }
}

.uiv2-only-sx {
    @media only screen and (max-width: 767px) {
        display: none;
    }
}


.uiv2-verification-container {
    background-color: $whiteColor;
    background: $primaryGradient;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .uiv2-verification-container-inner {
        max-width: 480px;
        padding: 15px;

        p {
            font-size: 1rem;
        }

        img {
            width: 270px;
            height: auto;
        }
    }
}