@media only screen and (max-width: 1200px) {
    .en-action-scroll {
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        display: block;
        width: 100%;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .en-marquee-container {
        width: 50%;
    }
}

@media only screen and (max-width: 991px) {
    .layout-sidebar-large .main-header .menu-toggle {
        margin-right: 15px;
        margin-left: 15px;
    }
}

@media only screen and (max-width: 767px) {

    html,
    body {
        overflow-x: hidden;
        clear: both;
    }

    .en-sm-none {
        display: none;
    }

    .en-login-left {
        margin-top: 40px;
    }

    .en-lock-out {
        right: 30px;
        top: -25px;
    }
}