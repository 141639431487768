.InputContainer .InputElement:focus {
    color: $whiteColor !important;
}

.layout-sidebar-large .sidebar-left-secondary,
.layout-sidebar-large .sidebar-left {
    background: #001114;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item {
    color: $whiteColor;
    border-bottom: 1px solid $blackColor;
    border-top: 1px solid #002228;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:hover {
    background-color: $cus-color-primary;
    transition-delay: 0.3ms;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active {
    color: $whiteColor;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .triangle {
    border-color: transparent transparent $cus-color-primary transparent;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:first-child {
    border-top: none;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:last-child::before {
    content: '';
    border-bottom: 1px solid #002228;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: -2px;
}

.layout-sidebar-large .sidebar-left-secondary.open {
    background-color: $whiteColor;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fbfbfb;
}

.modal-content {
    border: none;
    padding: 30px;

    img {
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 15px;
    }
}

.btn-dark {
    color: $whiteColor;
    background-color: $cus-color-primary;
    border-color: $cus-color-primary;
}

.error,
#card-errors {
    color: $dangerColor;
    font-size: 14px;
}

.cus-pay-title {
    width: 130px;
    display: inline-block;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.lvl1 {
    text-align: center;
    transition: 0.5s ease-in-out;
}

.btn.btn-outline-light.btn-svg {
    transition: 0.4s ease-in-out;
}

.btn.btn-outline-light.btn-svg.active,
.btn.btn-outline-light.btn-svg:hover {
    color: #ffffff;
}

.table-responsive {
    border-radius: 5px 5px 0 0;
}

// table 
.cus-table-container {
    .table {
        margin-bottom: 0;

        .thead-light {
            th {
                color: $headerColor;
                background-color: $secondaryColorAlt;
                border: none;
            }
        }
    }
}

.empty-item {
    padding: 30px 0;

    img {
        max-width: 220px;
    }
}

// Upload files
.en-upload-wrapper {
    .en-upload-img {
        position: relative;
        width: 100%;
        height: 100px;
        display: inline-block;
        overflow: hidden;
        border-radius: 8px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 1;
            border-radius: 8px;
        }

        .en-close-product {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    .custom-file-input {
        height: calc(3.5rem + 2px);
    }

    .input-group-text {
        border: 1px solid #ced4da;
        background-color: #ffffff;
        border-radius: 5px;
        height: 50px;
        color: #a3a3a3;
    }

    .input-group>.custom-file:not(:first-child) .custom-file-label {
        padding: 15px;
        height: 50px;
    }

    .custom-file-label {
        top: 0;
        left: 0;
        border-radius: 5px;
    }

    .custom-file-label::after {
        height: 50px;
        padding: 15px;
        color: #f4f5fc;
        content: "Browse";
        background-color: transparent;
    }
}

//time-picker

.en-time-picker {
    &.form-group {
        .form-control {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-right: 5px;
        }
    }
}

// Setting 
.menu-icon-grid {
    width: 280px;

    a {
        width: 8rem;

        &:hover {
            &:not([href]):not([tabindex]) {
                color: $whiteColor;
            }
        }
    }
}

//tab
.card-header {
    .btn-link {
        padding: 0.4rem 0.2rem;
        font-weight: 600;
        color: #212121;
        font-size: 1.2rem;
    }
}

.dropdown-linner {
    cursor: pointer;
}

@media (max-width: 768px) {
    .modal-content {
        padding: 0;
    }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #fba211;
    border: 1px solid #ffffff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #ffffff;
}

dp-day-calendar.dp-material .dp-selected {
    background: #FCA311 !important;
}

.ng-select .ng-clear-wrapper {
    width: 36px !important;
    margin-top: 3px;
}

@media only screen and (max-width: 767px) {
    .modal {
        .modal-xl {
            max-width: 100% !important;
        }
    }

    .btn.btn-outline-light.btn-svg {
        margin-bottom: 5px;
    }

    .modal-content {
        padding: 15px;
    }
}