/*
 *
 *   GULL - Angular Bootstrap Admin Template
 *
 *
*/

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~ngx-toastr/toastr.css";
@import "~angular-calendar/css/angular-calendar.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~highlight.js/styles/github.css";
@import "~ladda/dist/ladda.min.css";
@import "../iconsmind/iconsmind.css";
@import "variables";
// @import 'themes/lite-blue';
@import "themes/lite-purple";
@import "../vendor/bootstrap/bootstrap.scss";
@import "bootstrap-rtl.scss";
@import "globals/globals";
@import "custom/temp-raja";
/* Wizard */
@import '../../../../node_modules/angular-archwizard/styles/archwizard.css';

// UI master
@import "custom/session";
@import "custom/helperclass";
@import "custom/overright";
@import "custom/custom";
@import "custom/responsive";

@import "../vendor/dropzone";
// raja

@import '~dropzone/dist/min/dropzone.min.css';

// uiupdate 2023
@import '../app/uiupdates/utilities';
@import '../app/uiupdates/common-components';
@import '../app/uiupdates/auth';
@import '../app/uiupdates/settings';