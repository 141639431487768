//table action buttons

.top-right-btn {
    background-color: $secondaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: $whiteColor;
    padding: 5px 15px;
    border-radius: $borderRadius8x;
    transition: 0.4s ease-in-out;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        background-color: $primaryDarkColor;
        color: $whiteColor;
    }
}

//Action btn
.action-btn-exten {
    display: inline-block;
    cursor: pointer;
    background-color: $secondaryLightColor;
    width: 32px;
    height: 32px;
    margin-left: 5px;
    text-align: center;
    padding-top: 3px;
    border-radius: 50%;
    transition: 0.3ms;
}

.cus-action {
    ul {
        padding: 0;
        list-style: none;
        margin: 0;

        li {
            @extend .action-btn-exten;

            &.cus-view {
                background-color: rgba($viewColor, 0.2);
                transition: 0.4s ease-in-out;

                svg {
                    fill: $headerColor;
                }

                &:hover {
                    background-color: rgba($viewColor, 1);

                    svg {
                        fill: $headerColor;
                    }
                }
            }

            &.cus-edit {
                background-color: rgba($successColor, 0.2);
                transition: 0.4s ease-in-out;

                svg {
                    fill: $successColor;
                }

                &:hover {
                    background-color: rgba($successColor, 1);

                    svg {
                        fill: $whiteColor;
                    }
                }
            }

            &.cus-delete {
                background-color: rgba($dangerColor, 0.2);
                transition: 0.4s ease-in-out;

                svg {
                    fill: $dangerColor;
                }

                &:hover {
                    background-color: rgba($dangerColor, 1);

                    svg {
                        fill: $whiteColor;
                    }
                }
            }
        }
    }
}

//Breadcrumb
.breadcrumb {
    display: block;
    flex-wrap: inherit;
    border-bottom: 1px solid $fieldBorder;
    padding-bottom: 1rem;
    border-radius: 0;
    //padding: 0 0 15px 0;
    //border-radius: 5px;
    //box-shadow: #00000014 0 0px 3px, #00000014 0 1px 2px;
    margin-bottom: 1rem;

    .en-breadcrumb-inner {
        h1 {
            font-size: 1.2rem;
            display: inline-block;
            vertical-align: middle;
            font-weight: 700;

            span {
                margin-left: 4px;
                vertical-align: middle;
            }
        }

        ul {
            display: inline-block;
            vertical-align: middle;

            li {
                float: left;
                padding: 0;
                margin-right: 10px;

                &::after {
                    display: none;
                }

                &::before {
                    display: none;
                }

                a {
                    padding-left: 15px;
                    position: relative;
                    font-weight: 500;
                    color: $cus-color-primary;
                    transition: 0.4s ease-in-out;
                    font-family: $font-family-base;
                    font-size: 1rem;

                    &:hover {
                        color: $secondaryColor;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        background-color: $secondaryColor;
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        left: 0;
                        top: 8px;
                    }
                }
            }
        }
    }
}

//Status bar
.cus-order-status {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;

    ul {
        list-style: none;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
            position: relative;
            display: inline-block;
            padding: 0;
            transition: 0.4s ease-in-out;

            .cus-en-payment {
                position: relative;

                .engaje-payment {
                    position: initial;
                    padding: 10px 10px 10px 40px;
                    color: $whiteColor;
                    background-color: $secondaryColor;
                    border-color: $secondaryColor;
                    transition: 0.4s ease-in-out;

                    &.active,
                    &:hover {
                        background-color: $secondaryColor;
                    }
                }
            }

            &::after {
                display: none;
            }

            &.active,
            &:hover {
                a {
                    color: $whiteColor;
                    border-color: $whiteColor;
                    background-color: $secondaryColor;
                }

                svg {
                    fill: $whiteColor;
                }

                span {
                    background-color: $successColor;
                    color: $whiteColor;
                }
            }

            span {
                position: absolute;
                background-color: $successColor;
                color: $whiteColor;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 5px;
                right: -3px;
                top: -10px;
                font-size: 10px;
                font-weight: 400 !important;
                border-radius: 25px;
            }

            a {
                font-size: 14px;
                border-bottom: none;
                margin-left: 8px;
                font-weight: 400;
                color: $blackColor;
                transition: .4s ease-in-out;
                background-color: $secondaryLightColor;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                padding: 10px;
                border-radius: 8px;

                svg {
                    fill: $bodyColor;
                    vertical-align: bottom;
                    margin-right: 5px;
                }

                small {
                    font-size: 100%;
                    margin-left: 3px;
                }
            }

            // &.en-status-pending {
            //     transition: 0.4s ease-in-out;

            //     span {
            //         background-color: rgba($secondaryColor, 0.2);
            //     }

            //     &.active,
            //     &:hover {
            //         a {
            //             color: $secondaryColor;
            //             border-color: $secondaryColor;
            //         }

            //         svg {
            //             fill: $secondaryColor;
            //         }

            //         span {
            //             background-color: rgba($secondaryColor, 1);
            //             color: $whiteColor;
            //         }
            //     }
            // }

            // &.en-status-processing {
            //     transition: 0.4s ease-in-out;

            //     span {
            //         background-color: rgba($viewColor, 0.2);
            //     }

            //     &.active,
            //     &:hover {
            //         a {
            //             color: $viewColor;
            //             border-color: $viewColor;
            //         }

            //         svg {
            //             fill: $viewColor;
            //         }

            //         span {
            //             background-color: rgba($viewColor, 1);
            //             color: $whiteColor;
            //         }
            //     }
            // }

            // &.en-status-ready {
            //     transition: 0.4s ease-in-out;

            //     span {
            //         background-color: rgba(204, 204, 204, 0.4);
            //     }

            //     &.active,
            //     &:hover {
            //         a {
            //             color: $cus-color-primary;
            //             border-color: $cus-color-primary;
            //         }

            //         svg {
            //             fill: $cus-color-primary;
            //         }

            //         span {
            //             background-color: rgba($cus-color-primary, 1);
            //             color: $cus-color-primary;
            //         }
            //     }
            // }

            // &.en-status-completed {
            //     transition: 0.4s ease-in-out;

            //     span {
            //         background-color: rgba($successColor, 0.2);
            //     }

            //     &.active,
            //     &:hover {
            //         a {
            //             color: $successColor;
            //             border-color: $successColor;
            //         }

            //         svg {
            //             fill: $successColor;
            //         }

            //         span {
            //             background-color: rgba($successColor, 1);
            //             color: $whiteColor;
            //         }
            //     }
            // }

            // &.en-status-cancelled {
            //     transition: 0.4s ease-in-out;

            //     span {
            //         background-color: rgba($dangerColor, 0.2);
            //     }

            //     &.active,
            //     &:hover {
            //         a {
            //             color: $dangerColor;
            //             border-color: $dangerColor;
            //         }

            //         svg {
            //             fill: $dangerColor;
            //         }

            //         span {
            //             background-color: rgba($dangerColor, 1);
            //             color: $whiteColor;
            //         }
            //     }
            // }

            // &.en-status-receipts {
            //     transition: 0.4s ease-in-out;

            //     span {
            //         background-color: rgba($headerColor, 0.2);
            //     }

            //     &.active,
            //     &:hover {
            //         a {
            //             color: $headerColor;
            //             border-color: $headerColor;
            //         }

            //         svg {
            //             fill: $headerColor;
            //         }

            //         span {
            //             background-color: rgba($headerColor, 1);
            //             color: $whiteColor;
            //         }
            //     }
            // }
        }
    }

    &.view-order-container {
        ul {
            li {
                margin-left: 15px;
                vertical-align: middle;
                transition: 0.4s ease-in-out;

                &:hover {
                    svg {
                        fill: $primaryDarkColor;
                    }
                }

                h6 {
                    margin-bottom: 0;

                    a {
                        color: $primaryDarkColor;
                        margin-left: 0;
                        border: none;
                        transition: 0.4s ease-in-out;

                        &:hover {
                            color: $cus-color-primary;
                        }
                    }
                }
            }
        }
    }
}

// add product button
.bage-btn {
    background-color: rgba($headerColor, 0.2);
    color: $headerColor;
    border: none;
    padding: 5px 10px;
    border-radius: 25px;
    transition: 0.4s ease-in-out;

    svg {
        fill: $headerColor;
    }

    &:hover {
        background-color: rgba($headerColor, 1);
        color: $headerColor;

        svg {
            fill: $headerColor;
        }
    }

    &.bage-add {
        background-color: rgba($successColor, 0.2);
        color: $successColor;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        transition: 0.4s ease-in-out;

        svg {
            fill: $successColor;
        }

        &:hover,
        &:focus {
            background-color: rgba($successColor, 1);
            color: $whiteColor;

            svg {
                fill: $whiteColor;
            }
        }
    }

    &.bage-remove {
        background-color: rgba($dangerColor, 0.2);
        color: $dangerColor;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        transition: 0.4s ease-in-out;

        svg {
            fill: $dangerColor;
        }

        &:hover,
        &:focus {
            background-color: rgba($dangerColor, 1);
            color: $whiteColor;

            svg {
                fill: $whiteColor;
            }
        }
    }
}

//en-grid btn
.en-grid {
    .btn {
        padding: 0.3rem 1rem;
    }

    .btn-outline-light {
        color: #212121;
        background-color: #efefef;
        border-color: #efefef;
        border-radius: 5px;
    }
}

//btn-order-group
.btn-order-group {
    background-color: rgba($cus-color-primary, 1);
    color: $whiteColor;
    padding: 0.4rem 1rem;
    transition: 0.4s ease-in-out;

    &:hover {
        background-color: rgba($secondaryColor, 1);
    }
}

.btn-order-group-sub {
    background-color: rgba($secondaryColor, 1);
    color: $whiteColor;
    padding: 0.4rem 1rem;
    transition: 0.4s ease-in-out;

    &:hover {
        background-color: rgba($cus-color-primary, 1);
    }
}

.en-grid-list-container {
    .card {
        position: relative;

        .badge {
            background-color: rgba($secondaryColor, 1);

            h2,
            h6 {
                color: $whiteColor;
            }
        }

        .en-edit-btn {
            @extend .action-btn-exten;
            background-color: rgba($secondaryColor, 1);
            padding: 0;
            width: 36px;
            height: 36px;

            svg {
                fill: $whiteColor;
            }

            &:hover {
                background-color: rgba($secondaryColor, 1);

                svg {
                    fill: $whiteColor;
                }
            }
        }
    }

    //list-grid
    // &.list-grid {}
    //list-horizontal
    &.list-horizontal {
        .list-item {
            .list-thumb {
                img {
                    height: 80px;
                    width: 100px;
                }
            }

            .item-title {
                width: 230px;
            }
        }
    }
}

.item-title {
    h6 {
        span {
            display: inline-block;

            &:first-letter {
                text-transform: capitalize;
            }
        }
    }
}

//account-settings-wrapper
.account-settings-wrapper {
    .account-setting-header {
        background-color: $secondaryColor;
        border-radius: 20px 20px 0 0;
        height: 200px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            background-position: left top;
            background-size: cover;
        }
    }

    .en-avatar-section {
        background-color: $secondaryLightColor;

        .outer-avatar {
            position: relative;
            width: 115px;

            .en-avatar-edit {
                position: absolute;
                right: 0;
                z-index: 1;
                top: -45px;

                input {
                    display: none;

                    +label {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        margin-bottom: 0;
                        border-radius: 100%;
                        background: $whiteColor;
                        text-align: center;
                        padding-top: 6px;
                        box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
                        cursor: pointer;
                        font-weight: normal;
                        transition: all 0.2s ease-in-out;

                        svg {
                            fill: $cus-color-primary;
                        }

                        &:hover {
                            background: $secondaryColor;

                            svg {
                                fill: $whiteColor;
                            }
                        }
                    }
                }
            }
        }

        .avatar {
            position: relative;
            border: 3px solid $whiteColor;
            background-color: $whiteColor;
            display: inline-block;
            border-radius: 50%;
            margin-top: -45px;
            margin-left: 15px;
            width: 100px;
            height: 100px;
            overflow: hidden;

            img {
                position: relative;
                width: 100%;
                object-fit: cover;
                position: relative;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
            }
        }
    }

    .en-profile-details {
        background-color: $secondaryLightColor;
        padding: 2rem;
        border-radius: 0 0 20px 20px;

        .en-cus-name {
            // padding-left: 130px;
            // padding-bottom: 40px;
            // margin-top: -70px;

            h4 {
                font-size: 1.5rem;
                font-weight: 700;
            }

            h6 {
                color: $cus-color-primary;

                svg {
                    fill: $cus-color-primary;
                }
            }
        }

        .en-address-details {
            //background-color: $primaryColor;
            //padding: 30px 20px;
            //border-radius: 10px;

            small,
            h6 {
                color: $headerColor;
            }

            svg {
                fill: $headerColor;
            }
        }

        .en-media-type {
            overflow-y: scroll;
            max-height: 150px;
        }
    }
}

// Dashboard customer
.en-customer-title {
    background-color: $secondaryColorAlt;
    padding: 15px 15px;
    color: $headerColor;
    font-weight: 600;
    font-size: 1rem;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.en-customer-details {
    ul {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding: 15px 10px 15px 65px;
            border-bottom: 1px solid $secondaryLightColor;

            &:last-child {
                border-bottom: none;
            }

            .en-initial {
                position: absolute;
                text-transform: capitalize;
                background-color: $primaryColorAlt;
                color: $headerColor;
                width: 42px;
                height: 42px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                border-radius: 50%;
                left: 10px;
                top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .en-name-first-last {
                color: $headerColor;
                font-size: 0.875rem;

                &::first-letter {
                    text-transform: capitalize;
                }

                display: inline-block;
            }

            .en-badge {
                float: right;
                background-color: $primaryColorAlt;
                color: $headerColor;
                padding: 2px 15px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

// Add product
.en-product-group {
    padding: 35px 15px 10px;
    border: 1px dashed #ebebeb;
    border-radius: 10px;
    margin-bottom: 10px;
}

.en-add-new-item {
    padding: 10px 12px;
    background-color: $secondaryLightColor;
    border-radius: 25px;

    small {
        color: $successColor;
        font-weight: 200;
    }
}

// Action setting 
.en-social-media {
    position: relative;

    .en-media-type {
        .en-social-media-inner {
            display: inline-block;
            position: relative;
            background-color: $secondaryLightColor;
            padding: 5px 30px 5px 10px;
            border-radius: 12px;
            margin-right: 5px;
            margin-bottom: 2px;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &:hover {
                background-color: rgba($whiteColor, 0.3);
            }

            .en-social-media-img {
                img {
                    width: 18px;
                    height: 18px;
                }

                span {
                    color: $headerColor;
                    font-weight: 400;
                    margin-left: 5px;
                    font-size: 0.875rem;
                }
            }

            .en-close-product {
                position: absolute;
                right: 8px;
                top: 6px;
                cursor: pointer;
                transition: 0.4s ease-in-out;

                svg {
                    fill: $headerColor;
                }

                &:hover {
                    svg {
                        fill: $dangerColor;
                    }
                }
            }
        }
    }

    .social-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $primaryColorAlt;
        color: $headerColor;
        padding: 4px 10px;
        font-size: 14px;
        font-weight: 600;
        transition: 0.4s ease-in-out;

        svg {
            fill: $headerColor;
        }

        &:hover {
            background-color: $cus-color-primary;

            svg {
                fill: $secondaryColor !important;
            }
        }
    }
}

// Edit absolute btn
.en-edit-abb-btn {
    @extend .action-btn-exten;
    position: absolute;
    background-color: rgba($whiteColor, 1);
    padding: 0;
    width: 36px;
    height: 36px;
    top: 10px;
    right: 10px;
    transition: 0.4s ease-in-out;

    &:hover {
        background-color: $viewColor;
    }
}

// Flex
.en-breadcrumb-inner {
    display: flex;
    align-items: center;
}

// Promo code
.en-promo-container {
    position: relative;

    .en-promo-inner {
        width: 100%;

        .form-check-input {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.en-table-card {
    position: absolute;
    left: 15px;
    bottom: 0;

    h4 {
        font-size: 18px;
        font-weight: 600;
        color: $whiteColor;
    }

    p {
        color: $whiteColor;
    }
}

// Model popu

.form-group {
    position: relative;

    label {
        position: absolute;
        background-color: #fff;
        padding: 0 2px;
        z-index: 1;
        font-size: 12px;
        top: -10px;
        left: 14px;
        color: #999;

        &.checkbox {
            background-color: transparent;
            color: #f4f5fc;
            position: relative;
            left: 0;
            top: 0;
        }
    }

    .form-control {
        background: transparent;
        border: 1px solid #ced4da;
        border-radius: 5px;

        &:focus {
            box-shadow: none;
        }
    }
}

.modal-content {
    border-radius: 15px;

    .modal-body {
        .form-group {
            position: relative;

            label {

                //left: 0;
                &.checkbox {
                    padding-left: 30px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

//Select
.en-country-code {
    .iti {
        &.separate-dial-code {
            input {
                background: #ffffff;
                border: 1px solid #E6E7E9;
                border-radius: 8px;
            }
        }
    }
}

.ng-select {
    &.ng-select-single {
        .ng-select-container {
            background: transparent;
            border: 1px solid #E6E7E9;
            border-radius: 8px;
        }
    }
}


.ng-select.ng-select-multiple .ng-select-container {
    background: transparent;
    border: 1px solid #E6E7E9;
    border-radius: 8px;
}

// en-sparate-radio-wrapper
.form-group {
    &.en-sparate-radio-wrapper {
        label {
            left: 0;
        }

        .form-check {
            margin-top: 15px;

            label {
                position: initial;
                padding-left: 25px;
                margin-right: 10px;
            }
        }
    }
}


//Marquee 
.en-marquee-container {
    margin: auto;
    width: 60%;
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 999;
}

.inactive_status {
    width: 100%;
}

.marquee {
    position: relative;
    width: 100%;
}

marquee {
    background: linear-gradient(-135deg, #ffd1ce, #ffd3d0);
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -o-box-align: center;
    align-items: center;
    overflow: hidden;
}

.marquee-icon {
    position: absolute;
    background-color: #ffd2cf;
    padding: 8px 6px;
    border-radius: 12px;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.marquee-content {
    display: flex;
    -webkit-animation: marquee 10s linear infinite running;
    animation: marquee 10s linear infinite running;
    color: #cb0000;
    font-weight: 500;
    font-size: 14px;
}

.marquee-content:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
    animation-play-state: paused;
}

.marquee-tag {
    margin-left: 12px;
    display: -ms-inline-flexbox;
    display: inline-box;
    display: inline-flex;
    -o-box-align: center;
    align-items: center;
    -o-box-pack: center;
    justify-content: center;
    transition: all .2s ease;
}

.marquee-tag a {
    color: #000000;
}

.marquee-tag:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}

//Search 
.cus-search-wrapper {
    position: relative;

    .search {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background-color: #efefef;
        border-radius: 8px;
        font-size: 0.875rem;
        padding: 0rem 0 0rem 2.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        height: 40px;
    }

    img {
        position: absolute;
        left: 12px;
        top: 11px;
    }
}

// Date time picker
dp-date-picker {
    width: 100%;

    &.dp-material {
        .dp-picker-input {
            box-sizing: border-box !important;
            width: 100% !important;
            font-size: 15px !important;
            color: $black;
            outline: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border: 1px solid #E6E7E9;
            border-radius: 8px;
            background-color: $white;
            background: transparent;
            height: calc(2.4em + 0.75rem + 2px) !important;
            padding: 0.5rem 1rem;
        }
    }

    .dp-material {
        &.dp-popup {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
            border-left: none;
            border-right: none;
            border-bottom: none;
            z-index: 9999;
            white-space: nowrap;
        }
    }

    dp-calendar-nav {
        &.dp-material {
            .dp-calendar-nav-container {
                height: 40px;
                border: none;
            }
        }
    }

    dp-day-calendar {
        &.dp-material {
            .dp-calendar-wrapper {
                border: none;
                padding: 15px;
            }

            .dp-calendar-weekday {
                color: $black;
                border: none;
                font-size: 11px;
            }

            .dp-current-day {
                border: none;
                background-color: $success-color;
            }
        }

        .dp-calendar-day {
            font-size: 12px;
        }
    }

    dp-day-time-calendar {
        &.dp-material {
            dp-time-select {
                border: none;
                border-top: 1px dashed #dcdcdc;
            }
        }
    }

    dp-month-calendar {
        &.dp-material {
            .dp-calendar-month {
                font-size: 12px;
                width: 45px;
                height: 45px;
                padding: 15px 0;
            }

            .dp-current-month {
                border: none !important;
                background-color: $success-color;
            }
        }
    }
}

.badge-warning {
    color: #000000;
}

textarea {
    border-radius: 5px;
}

.empty-item-add {
    height: calc(100vh - 350px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @include breakpoint(1366px) {
        height: calc(100vh - 270px);

        img {
            width: 70%;
        }
    }
}

.cus-table-container {
    .table-responsive {
        border-radius: 12px;

        .table th,
        .table td {
            color: $headerColor;
            font-size: 0.875rem;
            border-top: 1px solid $secondaryLightColor;
        }
    }

    .bg-ask {
        border-radius: 12px;
    }
}