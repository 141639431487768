body {
    font-family: $font-family-base;
    color: $bodyColor;
    font-size: 0.875rem;
}

a {
    color: $primaryColor;
    transition: 0.4s ease-in-out;

    &:hover {
        color: $primaryDarkColor;
    }
}

.text-bg-white {
    background-color: $whiteColor !important;
}

.text-bg-black {
    background-color: $blackColor !important;
}

.text-bg-primary {
    background-color: $primaryColor !important;
}

.text-bg-base {
    background-color: $bodyColor !important;
}

.text-bg-header {
    background-color: $headerColor !important;
}

.text-bg-danger {
    background-color: $dangerColor !important;
}

.text-bg-success {
    background-color: $successColor !important;
}

.text-bg-info {
    background-color: $infoColor !important;
}

.light-bg {
    background-color: $secondaryLightColor !important;
}

.text-white {
    color: $whiteColor !important;
}

.text-black {
    color: $blackColor !important;
}

.text-primary {
    color: $primaryColor !important;
}

.text-base {
    color: $bodyColor !important;
}

.text-header {
    color: $headerColor !important;
}

.text-danger {
    color: $dangerColor !important;
}

.text-success {
    color: $successColor !important;
}

.text-info {
    color: $infoColor !important;
}