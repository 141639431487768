//Variables
._wizard-indicator {
    position: absolute;
    content: '';
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
}

._flex-vertical-center {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.uiv2-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;

    .uiv2-logo {
        img {
            width: 60px;
            height: 60px;
        }
    }

    .nav-list {
        ul {
            padding: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            list-style: none;

            li {
                a {
                    padding: 0 10px;
                    font-size: 1rem;
                    font-weight: 500;
                    color: $headerColor;
                    text-transform: capitalize;

                    .uiv2-user-name {
                        background-color: $primaryColor;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.4rem;
                        font-family: 600;
                        margin-left: 0.4rem;
                    }
                }
            }
        }
    }
}

.uiv2-header {
    font-weight: 600;
}

// wizar

.uiv2-wizard-container {


    .card {
        @extend ._flex-vertical-center;
        background-color: transparent;
        padding: 30px;
        box-shadow: none;

        @media only screen and (max-width: 767px) {
            padding: 15px 0;
        }

        .card-block {
            width: 100%;
            padding: 15px;
            overflow-y: initial;
            clear: both;

            @media only screen and (max-width: 767px) {
                padding: 15px 0;
            }
        }

        .card-header {
            display: flex;
            justify-content: center;
            width: 100%;
            border: none;
            padding: 0 15px;

            @media only screen and (max-width: 767px) {
                padding: 0;
            }
        }

        .card-footer {
            background-color: transparent;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            @media only screen and (max-width: 767px) {
                padding: 0;
            }
        }
    }

    form-wizard {
        .card {
            .nav {
                display: block;

                .nav-item {
                    float: left;
                    margin-right: 10px;

                    a {
                        position: relative;
                        border-bottom: none;
                        font-size: 1rem;
                        height: 25px;
                        display: inline-block;
                        padding-left: 30px;
                        font-weight: 400;
                        line-height: 25px;
                        color: $bodyColor;

                        &::before {
                            background-image: url(../../../images/check_mute.svg);
                            @extend ._wizard-indicator;
                        }
                    }

                    &.enabled {
                        a {
                            color: $headerColor;

                            &::before {
                                background-image: url(../../../images/check_enable.svg);
                                @extend ._wizard-indicator;
                            }
                        }

                        &.active {
                            a {
                                background: transparent;
                                border-color: transparent;
                                color: $headerColor;

                                &::before {
                                    background-image: url(../../../images/check_active.svg);
                                    @extend ._wizard-indicator;
                                }
                            }
                        }
                    }
                }
            }

            .card-footer {
                .btn {
                    color: $whiteColor;
                    background-color: $secondaryColor;
                    background-image: none;
                    border-color: $secondaryColor;
                    border-radius: $borderRadius8x;
                    margin-left: 0.4rem;

                    &:disabled {
                        background: $primaryLightColor;
                        color: $headerColor;
                    }
                }
            }
        }
    }

    .ui2-default-box {
        position: relative;
        background-color: $whiteColor;
        border: 1px solid $secondaryLightColor;
        padding: 40px 20px 20px;
        border-radius: $borderRadius20x;
        margin-top: 20px;

        @media only screen and (max-width: 767px) {
            margin-top: 15px;
        }

        .content {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &.card-profile-1 {
            .avatar {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                background-color: $secondaryColor;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                }
            }
        }

        .cus-radio {
            position: absolute;
            top: 10px;
            right: 10px;

            input[type="radio"] {
                color: $whiteColor;
                border: 0.15em solid $whiteColor;
            }

            input[type="radio"]::before {
                box-shadow: inset 1em 1em $whiteColor;
            }

            input[type="radio"]:focus {
                outline: max(2px, 0.15em) solid $whiteColor;
            }
        }

        .uiv2-add {
            width: 100%;
            background-color: $successColor;
            color: $whiteColor;
            display: inline-block;
            padding: 5px 10px;
            border-radius: $borderRadius25x;
            margin-bottom: 10px;
            line-height: 1;
        }

        .en-offers-wrapper {
            position: absolute;
            top: 0;
            left: 15px;

            .en-inner-offers {
                position: absolute;
                width: 74px;
                top: 0;

                h4 {
                    margin-top: 8px;
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }
    }

    .ng-dropdown-panel {
        background-color: $whiteColor;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
        position: absolute;
        padding: 12px 0 15px 12px;
        border-radius: $borderRadius8x;
        margin-top: 1px;

        .ng-dropdown-panel-items {
            max-height: 150px;

            .ng-option {
                padding-bottom: 6px;
                padding-top: 6px;
            }
        }
    }

    //payment wizard
    .en-payment-control {
        form {
            position: relative;

            .pay-now-btn {
                width: 100%;
            }
        }
    }

    &.uiv2-wizard-add-product {
        .card {
            padding: 15px 0 0;

            .card-header {
                justify-content: flex-start;
                margin-bottom: 10px;
            }

            .card-header {
                padding-left: 0;
                padding-right: 0;
            }

            .card-block {
                padding-left: 0;
                padding-right: 0;
            }

            .card-footer {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .btn {
        &.btn-primary {
            width: auto;
        }
    }
}

.uiv2-review-info-container {
    .uiv2-review-info-container-inner {
        background-color: $whiteColor;
        padding: 20px;
        border-radius: $borderRadius20x;
        border: 1px solid $secondaryLightColor;
        margin-top: 20px;

        @media only screen and (max-width: 767px) {
            margin-top: 15px;
        }

        .uiv2-avatar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0.5rem;

            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $secondaryColor;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                margin-right: 0.5rem;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        &.uiv2-price-card {
            background-color: $secondaryColor;
        }
    }
}

//ribbon

.uiv2-box {

    .uiv2-ribbon {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: absolute;
        top: -1px;
        left: -1px;

        &::before {
            top: 0;
            right: 0;
        }

        &::after {
            bottom: 0;
            left: 0;

        }

        &::before,
        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            border: 5px solid $dangerColor;
            border-top-color: transparent;
            border-left-color: transparent;
        }

        span {
            background-color: $dangerColor;
            position: absolute;
            display: block;
            width: 250px;
            padding: 10px 0;
            box-shadow: 0 5px 10px #0000001a;
            color: $whiteColor;
            font-size: 12px;
            line-height: 1;
            text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
            text-transform: uppercase;
            text-align: center;
            right: -25px;
            top: 30px;
            transform: rotate(-45deg);
        }
    }
}

//Promo

.uiv2-promo {
    overflow-y: scroll;
    min-height: 75px;
    max-height: 210px;

    &::-webkit-scrollbar {
        width: 5;
        border-radius: 5px;
    }

    .uiv2-promo-wrapper {
        background-color: #FFE7C0;
        padding: 15px;
        border-radius: $borderRadius12x;
        margin-bottom: 5px;
    }

    .uiv2-per {
        i {
            margin-right: 5px;
            color: $primaryColor;
            font-size: 24px;
        }
    }

    .uiv2-rate {
        i {
            margin-right: 5px;
            color: $successColor;
            font-size: 24px;
        }
    }
}

// payment

.uiv2-payment-container {
    width: 420px;
    margin: 0 auto;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .uiv2-payment-card,
    .uiv2-card-info-wrapper {
        background-color: $whiteColor;
        border-radius: $borderRadius20x;
        border: 1px solid $primaryLightColor;
        padding: 20px;
    }

    .uiv2-card-info {
        margin-top: 0.5rem;
        background-color: $secondaryLightColor;
        padding: 20px;
        border-radius: $borderRadius8x;
    }
}

.uiv2-waiting-payment {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loader-bubble-primary {
        color: $successColor;
        margin: 0 0 20px;
    }
}

.or-active {
    background-color: $secondaryColor;
    color: $whiteColor;
}